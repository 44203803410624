@use '~@adair/core-ui/lib/library/style/breakpoints';
@use '~@adair/core-ui/lib/library/style/colors';
@use '~@adair/core-ui/lib/utilities/spacing/spacing.base';

.split-layout {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    min-height: 600px;
    width: 100%;
    flex-wrap: wrap;
    background-color: colors.$white;

    //
    // Sidebar
    //

    .sidebar {
        display:none;

        background: {
            size: cover;
            repeat: no-repeat;
            position: center center;
        }
    }

    @include breakpoints.media-up('lg') {
        .sidebar {
            display: block;
            width: 420px;
            height: 100%;
            position: relative;
        }
    }

    @include breakpoints.media-up('xl') {
        .sidebar {
            width: 560px;
        }
    }

    .sidebar__overlay {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: linear-gradient(15deg, rgba(colors.$black, .2),rgba(colors.$black, 0), rgba(colors.$black, .8));
        @include spacing.padding(8);
    }

    div.sidebar__overlay-text {

    }

    //
    // Main Content
    //

    .main-content {
        display: flex;
        flex: 1 1 0;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        position: relative;

        .main-content__interior {
            width: 100%;
            overflow-y: scroll;
            max-height: 100%;
        }

        .logo {
            position: absolute;
            top: 0;
            left: 0;
            @include spacing.padding(8);

            @include breakpoints.media-up('lg') {
                display: none;
            }
        }

        header {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            @include spacing.padding(4 0)
        }
    }
}
