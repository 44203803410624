@use '~@adair/core-ui/lib/library/style/colors';
@use '~@adair/core-ui/lib/library/style/font';
@use '~@adair/core-ui/lib/utilities/spacing/spacing.base';

$vert-breakpoint-xs: 400px;
$vert-breakpoint-sm: 800px;

.image-gallery {
    position: fixed;
    // width: 100%;
    // height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    pointer-events: none;

    &:focus {
        outline: none;
    }

    .-open &.image-gallery {
        .close {
            opacity: .6;
            @include spacing.margin(null null 6);
        }
    
        .slide-counter {
            opacity: 1;
        }
    }
}


.image-gallery .overlay__backdrop {
    background-color: rgba(colors.gray(900), .8);
}

.image-gallery__header {
    position: absolute;
    pointer-events: none;
    width: 100%;
    z-index: 10;
    @include spacing.margin(4 null);
    text-align: center;

    @media (min-height: $vert-breakpoint-xs) {
        position: relative;
    }

    @media (min-height: $vert-breakpoint-sm) {
        @include spacing.margin(8 null 4);
    }

    // Slide key
    .slide-counter {
        @include font.size(2);
        color: #fff;
        opacity: 0;
        font-style: italic;
        transition: all 850ms 650ms;
        cursor: default;
        
        .current {
            font-weight: 700;
        }

        .total {
            opacity: .6;
        }

        .divider {
            opacity: .25;
            @include font.size(3);
            margin: 0 8px;
        }
    }

    .favorite-icon {
        position: absolute;
        top: 50%;
        left: 32px;
        transform: translateY(-50%);
        pointer-events: auto;
        font-size: 18px;
        
        &:hover {
            &:after {
                font-size: 14px;
                position: absolute;
                left: 150%;
                top: 0;
                white-space: nowrap;
                color: colors.gray('600');
                line-height: 18px;
                content: 'Save To Favorites';
            }
        }
        
        &[data-favorite="true"] {
            &:hover:after {
                content: 'Remove From Favorites'
            }
        }
    }
}

.image-gallery__content {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    opacity: 0;
    transition: all 350ms;
    transform: scale(.9);

    // width: 100%;
    height: 400px;
    height: calc(100% - 30px);
    max-height: 1200px;
    
    @include spacing.margin(4 null null);

    @media (min-height: $vert-breakpoint-xs) {
        height: calc(100% - 120px);
        margin-top: 0;
    }

    @media (min-height: $vert-breakpoint-sm) {
        height: calc(100% - 250px);
    }

    .-open & {
        opacity: 1;
        transform: scale(1);
    }
}

.image-gallery__item {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @include spacing.padding(null 8);
    

    img {
        pointer-events: auto;
        display: block;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;
        // fix squashing of aspect ratio on chrome... potentially others.
        object-fit: scale-down;
        // pointer-events: none;

        cursor: grab;

        &:active {
            cursor: grabbing;
        }
    }
}

.image-gallery__controls {
    
    position: relative;
    width: 100%;
    margin-top: -16px;
    z-index: 20;
    display: none;
    opacity: 0;
    transition: opacity 350ms;

    > .button {
        pointer-events: auto;
    }

    @media (min-height: $vert-breakpoint-xs) {
        display: flex;
    }

    .-open & {
        opacity: 1;
    }
}

.image-gallery .close {
    position: fixed;
    top: 0%;
    right: 0%;
    @include spacing.margin(4 4 null null);
    opacity: 0;
    transition: opacity 850ms 450ms cubic-bezier(.32, .52, .37, .99),
                margin 850ms 450ms cubic-bezier(.32, .52, .37, .99),
                color 0ms,
                transform 250ms cubic-bezier(.32, .52, .37, 1.5);

    &:hover {
        color: colors.gray('100');
        transform: scale(1.25);
    }

    @media (min-height: $vert-breakpoint-sm) {
        top: auto;
        bottom: 0px;
        left: 50%;
        right: auto;
        transform: translate(-50%, 0%);
        @include spacing.margin(0);

        &:hover {
            transform: translate(-50%, 0%) scale(1.25);
        }
    }
}
