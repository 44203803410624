@use "~@adair/core-ui/lib/library/style/breakpoints";

html, body, #root {
    height: 100%;
}

.App {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.page {
    flex: 1 1 100%;
    display: grid;
    grid-template-columns: 1fr fit-content(420px);
    grid-template-rows: 100%;
    position: relative;
    min-height: 0;
}

.content {
    position: relative;
    overflow-y: scroll;
}

.opener {
    position: absolute;
    right: 0;
    top: 22px;
    padding: 0 24px;

    transition: all 350ms;

    // top: 50%;
    // transform: translateY(-50%);

    @include breakpoints.media-up('xl') {
        display: none;
    }

    z-index: 99;
}
