@use "~@adair/core-ui/lib/library/style/breakpoints";
@use "~@adair/core-ui/lib/library/style/colors";

.section-nav {
    position: relative;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    padding: 24px 0;
    min-height: 76px;

    @include breakpoints.media-up('sm') {
        height: 76px;
    }

    .menu__item.-active {
        position: relative;
        background-color: transparent !important;

        &:before {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            bottom: -16px;
            height: 3px;
            width: 100%;
            background-color: colors.$primary;
        }
    }
}
