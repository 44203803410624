.profile-image-manager {

    .profile-image-manager__preview-area {
      background-color: gray(100);
    }
  
    .image-preview {
      padding-bottom: 100%;
      background: {
        position: center;
        repeat: no-repeat;
        size: cover;
      }
      border-radius: 4px;
    }
  }
  