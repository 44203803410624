@use '~@adair/core-ui/lib/library/style/colors';
@use '~@adair/core-ui/lib/library/style/border-radius';
@use '~@adair/core-ui/lib/library/style/variables';

.tour-card {
    display: block;
    text-decoration: none;
    border: 1px solid colors.gray('300');
    border-radius: variables.$site--radius;
    overflow: hidden;

    .unfavorite {
        display: inline-block;
        position: absolute;
        top: 4px;
        right: 4px;
    }
}

a.tour-card:hover {
    box-shadow: 0 2px 24px rgba(colors.gray(900), .12);
}

.tour-card__inner {
    position: relative;
    padding-bottom: 56.25%;
    background-size: cover;
    background-color: colors.gray('800');
}

.tour-card__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-image: linear-gradient(rgba(colors.gray('900'), .7) 0%, rgba(colors.gray('900'), 0) 80%);

    > .buttons {
        display: none;

        .tour-card:hover & {
            display: flex;
        }
    }
}
