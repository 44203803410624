@use '~@adair/core-ui/lib/library/style/colors';
@use '~@adair/core-ui/lib/library/style/border-radius';
@use '~@adair/core-ui/lib/library/style/variables';

.plan-card {
    display: block;
    text-decoration: none;
    border: 1px solid colors.gray('300');
    border-radius: variables.$site--radius;
    overflow: hidden;

    &:hover {
        box-shadow: 0 2px 24px rgba(colors.gray(900), .12);
    }

    .unfavorite {
        position: absolute;
        top: 4px;
        right: 4px;
    }
}

.plan-card__inner {
    position: relative;
    padding-bottom: 56.25%;
    background-size: cover;
    background-position: center center;
    background-color: colors.gray('800');
    
}

.plan-card__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-image: linear-gradient(rgba(colors.gray('900'), .7) 0%, rgba(colors.gray(900), 0) 60%);

    > .buttons {
        display: none;

        .plan-card:hover & {
            display: flex;
        }
    }

    // > .box__footer {
    //     position: absolute;
    //     bottom: 0;
    //     left: 0;
    //     right: 0;
    //     background-color: colors.$white;
    //     opacity: 0;
    //     transform: translateY(100%);
    //     transition: transform 350ms 0ms, opacity 0ms 350ms;
    //     border-radius: variables.$site--radius;

    //     .plan-card:hover & {
    //         transition: transform 350ms 0ms, opacity 0ms;
    //         opacity: 1;
    //         transform: translateY(0%);
    //     }
    // }
}