@use "~@adair/core-ui/lib/library/style/breakpoints";
@use "~@adair/core-ui/lib/library/style/colors";
@use "~@adair/core-ui/lib/library/style/font";
@use "~@adair/core-ui/lib/utilities/spacing/spacing.base";
@use "~@adair/core-ui/lib/library/style/variables";

$nav-header-height: 56px;
$header-nav-height: 56px;

//
// Mobile Navigation
// --------------------

#mobile-nav {
    position: absolute;
    top: $nav-header-height;
    left: 0;
    bottom: 0%;
    background-color: colors.$white;
    overflow: hidden;
    width: 100%;
    max-width: 480px;
    z-index: variables.zindex('overlay');
}

.mn__link {
    @include spacing.padding(null null 6);

    > a {
        display: block;
        @include font.size(5);
        font-weight: 800;
        color: colors.gray("900");

        &:hover {
            color: colors.brand("green");
        }
    }
}
  
  //
  // Backdrop
  //
  
  
  #mn-backdrop {
    position: fixed;
    top: $nav-header-height;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(colors.gray('900'), 0.7);
    z-index: variables.zindex('overlay-backdrop');
  }
  