@use "~@adair/core-ui/lib/library/style/breakpoints";
@use "~@adair/core-ui/lib/library/style/colors";
@use "~@adair/core-ui/lib/utilities/spacing/spacing.base";

#information-bar {
    position: absolute;
    width: 0px;
    max-width: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    background-color: colors.gray("100");
    box-shadow: 0 0 48px rgba(colors.gray(900), 0.12);
    transition: width 350ms cubic-bezier(0.165, 0.84, 0.44, 1);
    z-index: 10;

    &.-open {
        width: 320px;
    }

    @include breakpoints.media-up('sm') {
        &.-open {
            width: 360px;
        }
    }

    @include breakpoints.media-up('md') {
        
    }
    
    @include breakpoints.media-up('lg') {
        &.-open {
            width: 420px;
        }
    }
    
    @include breakpoints.media-up('xl') {
        position: relative;
        box-shadow: none;
        width: 420px;
    }

    header {
        position: relative;
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 76px;
        border-bottom: 1px solid colors.gray("200");
        @include spacing.padding(null 6);

        .close {
            position: absolute;
            right: 24px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    footer {
        flex: 1 0 auto;
        // border-top: 1px solid colors.gray("200");
        @include spacing.padding(4 6 5);
    }

    .information-bar__content {
        flex: 1 1 100%;
        // overflow-y: scroll;
    }
}

.information-bar__inner {
    width: 100%;
    min-width: 320px;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
    
    @include breakpoints.media-up('sm') {
        min-width: 360px;
    }

    @include breakpoints.media-up('lg') {
        min-width: 420px;
    }
}
