@use "~@adair/core-ui/lib/library/style/colors";

.globabl-nav {}

.gn__main {
    background-color: colors.gray('900');
    color: colors.$white;
    height: 56px;
    display: flex;
    align-items: center;

    .menu__item {
        text-transform: uppercase;
        font-weight: 900;
        letter-spacing: .08em;
    }
}
