@use 'sass:color';
@use '~@adair/core-ui/lib/library/style/breakpoints';
@use '~@adair/core-ui/lib/library/style/colors';
@use '~@adair/core-ui/lib/library/style/elevate';
@use '~@adair/core-ui/lib/library/style/font';
@use '~@adair/core-ui/lib/utilities/spacing/spacing.base';

$height: 32px;

.timeline {
    position: relative;
    height: $height * .75;
    display: flex;
    align-items: stretch;
    border-radius: $height / 4;
    background-color: colors.gray('200');
    overflow: hidden;
}

.timeline__bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    border-radius: inherit;
    z-index: 2;
}

.timeline__label {
    width: 100%;
    display: none;
    text-align: center;
    @include font.size('xs');
    text-transform: uppercase;
    letter-spacing: .05em;
    font-weight: 900;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0px 12px 0px 16px;
    z-index: 1;
}


.timeline__phase:hover .timeline__label {
    opacity: 1;
    visibility: visible;
}

@include breakpoints.media-up('md') {
    .timeline {
        height: $height;
    }
    
    .timeline__label {
        display: block;
    }

    .timeline__bar {
        height: 10%;
    }
}

.timeline__phase {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 0%;
    padding: 8px;
    min-width: 0;
    cursor: pointer;
    border-right: 2px solid colors.$white;
    color: colors.gray('600');

    &:not(:first-of-type) {
        .timeline__label {
            padding-left: 24px;
        }
    }

    &:last-of-type {
        border-right: none;
        
        .timeline__phase-arrow {
            display: none;
        }
    }
    
    &:hover {
        background-color: colors.gray('300');
        color: colors.gray('800');
        
        .timeline__phase-arrow {
            fill:  colors.gray('300');
        }
    }

    &.-complete {
        color: colors.$white;
        background-color: colors.intent('success');

        @include breakpoints.media-down('md') {
            .timeline__bar {
                display: none;
            }    
        }

        .timeline__bar {
            background-color: color.adjust(colors.intent('success'), $lightness: -12% );
        }

        .timeline__phase-arrow {
            fill: colors.intent('success');
        }

        &:hover {
            background-color: color.adjust(colors.intent('success'), $lightness: -5% );
            
            .timeline__phase-arrow {
                fill: color.adjust(colors.intent('success'), $lightness: -5% );
            }   
        }
    }

    &.-active {
        color: colors.$white;
        background-color: colors.intent('primary');

        .timeline__bar {
            background-color: color.adjust(colors.intent('primary'), $lightness: -20% );
        }

        .timeline__phase-arrow {
            fill: colors.intent('primary');
        }

        &:hover {
            background-color: color.adjust(colors.intent('primary'), $lightness: -8% );
            
            .timeline__phase-arrow {
                fill: color.adjust(colors.intent('primary'), $lightness: -8% );
            } 
        }
    }
}

.timeline__phase-arrow {
    position: absolute;
    top: 0;
    left: 100%;
    transform: translateX(-20%);
    z-index: 1;
    height: 100%;
    width: auto;
    fill: colors.gray('200');

    .top { fill: inherit; }
    .btm { fill: colors.$white; }
}

@for $i from 1 through 10 {
    .timeline__phase:nth-of-type(#{$i}) {
        z-index: 11 - $i;
    }
}