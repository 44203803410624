@use "~@adair/core-ui/lib/library/style/breakpoints";

.timeline-page {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas: 
        "header"
        "description"
        "sidebar"
        "related";
    column-gap: 32px;
}

@include breakpoints.media-up('md') {
    .timeline-page {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto minmax(auto, 1fr);
        grid-template-areas: 
            "header header"
            "description description"
            "related sidebar";
        column-gap: 32px;
        column-gap: calc(var(--spacer-scale--4) * 2);
    }
}

@include breakpoints.media-up('lg') {
    .timeline-page {
        grid-template-columns: 7fr 5fr;
    }
}

.timeline-page__header {
    grid-area: header;
}

.timeline-page__description {
    grid-area: description;
}

.timeline-page__sidebar {
    grid-area: sidebar;
}
.timeline-page__related {
    grid-area: related;
}
