@use '~@adair/core-ui/lib';
// Templates
@use './layout/templates/base.scss';
@use './layout/templates/split.scss';
// Pages
@use './layout/pages';
// partials
@use './layout/partials';

// quick hack to disable inputs when an employee is in preview mode for a customer
.page.preview-mode {
    .input, input, button[type=submit] {
        pointer-events: none;
    }
}
